export const data = [

    { id: 1, imgUrl: 'https://imgur.com/rOzDLbC.jpg' },

    { id: 2, imgUrl: 'https://imgur.com/Ix0HTXt.jpg'},

    { id: 3, imgUrl: 'https://imgur.com/DqAawXx.jpg'},

    { id: 4, imgUrl: 'https://imgur.com/hZI1eOE.jpg' },

    { id: 5, imgUrl: 'https://imgur.com/btCPkdK.jpg'},

    { id: 6, imgUrl: 'https://imgur.com/Hv0Qo2D.jpg' },

    { id: 7, imgUrl: 'https://imgur.com/cTaPHa7.jpg' }
];